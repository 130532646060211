import React, { useState } from "react"
// import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import Img from "gatsby-image"

//import css
import "./../../styles/integrations.css"
import "./../../styles/products.css"
//import components
import GetStartedBanner from "./../../components/get-started-footer"

const Integrations = ({ data }) => (
  <Layout>
    <SEO
      title="Third Party Integrations & Notifications"
      description="Odown's powerful third party integrations make it easy to monitor your website and APIs. Get comprehensive alerts and insights to keep your systems running smoothly."
      pathname={`/integrations/`}
      image={{
        src: "/general-image-og--integrations.jpg",
        width: 1600,
        height: 800,
      }}
    />
    {/* HEADER  */}
    <HeadingSection />
    {/* section : Why 
			- In recent years, the way teams communicate has changed; we're seeing tools like Slack and Microsoft Teams replace traditional email methods, and that, in turn, has reduced the urgency associated with email.
			- We see more and more teams communication tools like Slack or Microsoft Teams replacing emails, and as a result, email is no longer as urgent as it once was.
			- Team communication styles have changed significantly since email became the dominant method of communication. Nowadays, tools like Slack and Microsoft Teams are taking over the traditional email methods, and with that, email has lost its urgency.
		*/}
    <WhyNeed3rdPartyIntergration_section data={data} />
    <WebsiteDowntimeImpact_section data={data} />
    {/* Show all the available integration  */}
    <AvailableIntegrationTools_section />
    {/* Get started banner */}
    <GetStartedBanner />
  </Layout>
)

/**
 *
 * @param {*} param0
 * @returns
 */
const HeadingSection = ({ data }) => {
  return (
    <div className="u-features integration-hero">
      <div className="container">
        <h1 className="u-features__heading-title">
          Customize your website downtime alerts as you like
        </h1>
        <a className="hero_sub-title" href="#integrations">
          See what Odown integrates with
        </a>
      </div>
    </div>
  )
}

/**
 *
 * @param {*} params
 * @returns
 */
const WhyNeed3rdPartyIntergration_section = ({ data }) => {
  return (
    <section className="u-main-feature left-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="u-main-feature_tc-title">
              3rd party integrations are needed for complete website monitoring
            </h2>
            <p className="u-main-feature_tc-desc">
              Slack and Microsoft Teams have replaced traditional email methods
              as the way a team communicates, which, in turn, has reduced the
              urgency associated with email.
            </p>
            <p className="u-main-feature_tc-desc small">
              Your team must be alerted to the issue as quickly as possible when
              your site goes down. Fixing the problem starts once you’re aware
              of it. So the integrations allow you to bring Odown into the tools
              you already use and love. Make our website monitoring fit around
              your workflow rather than requiring you to alter your workflow to
              fit Odown!
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.ImpactAllIntegrationsBrand.childImageSharp.fluid}
                alt="Integrations - 3rd party integrations are needed for complete website monitoring"
              />
              {/* <Img fadeIn={false} loading="eager" src="/integration-all-in-one.svg" alt="Integrations - 3rd party integrations are needed for complete website monitoring" />  */}
              {/* <img src="/integration-all-in-one.svg" alt="Integrations - 3rd party integrations are needed for complete website monitoring" />   */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/**
 *
 * @param {*} params
 * @returns
 */
const WebsiteDowntimeImpact_section = ({ data }) => {
  return (
    <section className="u-main-feature right-text">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <h2 className="u-main-feature_tc-title">
              Impact of website downtime alerts on your business
            </h2>
            <p className="u-main-feature_tc-desc">
              Most email alert notifications are opened, but a certain
              percentage is never seen.
            </p>
            <p className="u-main-feature_tc-desc small">
              It means that each year there are hundreds of thousands of
              downtime events missed simply because the alert got missed.
              Downtime recovery is +30% slower than in teams with integrations.
              It's simple that if you learn about the problem sooner, the
              resolution will happen faster, which is why our website monitoring
              tool is born.
            </p>
          </div>
          <div className="u-main-feature__media-content">
            <div className="illustration-wrapper">
              <Img
                fadeIn={false}
                loading="eager"
                fluid={data.ImpactAlertOnBusinessImage.childImageSharp.fluid}
                alt="Integrations - Impact of website downtime alerts on your business"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const AvailableIntegrationTools_section = ({ data }) => {
  const integrations = [
    {
      name: "Slack",
      handlename: "slack",
      description:
        "Slack messages are a great way to inform the entire team of downtime.",
      icon: "slack.svg",
      supported: true,
    },
    {
      name: "Webhooks",
      handlename: "webhooks",
      description:
        "For advanced alerting, you can set up webhooks to your own system or app.",
      icon: "webhooks.svg",
      supported: true,
    },
    {
      name: "Telegram",
      handlename: "telegram",
      description: "Telegram messages are a great way how to stay alerted.",
      icon: "integration-telegram.svg",
      supported: true,
    },
    {
      name: "Discord",
      handlename: "discord",
      description:
        "Get important monitor status updates in your Discord messages.",
      icon: "integration-discord.svg",
      supported: true,
    },
    {
      name: "PagerDuty",
      handlename: "pagerduty",
      description:
        "Send up & down events and auto-resolve your incidents in PagerDuty.",
      icon: "pagerduty-icon.svg", //'pagerduty.png',
      supported: true,
    },
    {
      name: "Opsgenie",
      handlename: "opsgenie",
      description: "Receive Odown alerts in your OpsGenie account",
      icon: "opsgenie.png",
      supported: true,
    },
    {
      name: "Twilio",
      handlename: "twilio",
      description:
        "Connect your account and send to your status page subscribers the SMS notifications ",
      icon: "integration-twilio.svg",
      supported: true,
    },
    {
      name: "Vonage(Nexmo)",
      handlename: "vonage",
      description:
        "Connect your account and send to your status page subscribers the SMS notifications ",
      icon: "integration-vonage.svg",
      supported: true,
    },
    {
      name: "Zapier",
      handlename: "zapier",
      description: "Integrate your Zapier account to get alerted right away.",
      icon: "integration-zapier.svg",
      supported: false,
    },
    {
      name: "Pushbullet",
      handlename: "pushbullet",
      description:
        "Get instant alerts via mobile push notifications in the Pushbullet Android app.",
      icon: "integration-pushbullet.svg",
      supported: false,
    },
    {
      name: "Microsoft Team",
      handlename: "microsoftteam",
      description:
        "Get notifications inside your MS Teams app to alert everyone in the group.",
      icon: "integration-microsoft-teams.svg",
      supported: false,
    },
    {
      name: "Google Chat",
      handlename: "googlechat",
      description:
        "Get notifications into the Google Chat. Just copy the room URL to Odown.",
      icon: "integration-hangouts.svg",
      supported: false,
    },
    {
      name: "Pushover",
      handlename: "pushover",
      description:
        "Get instant alerts via mobile push notifications in Pushover app.",
      icon: "integration-pushover.svg",
      supported: false,
    },
    {
      name: "Twitter",
      handlename: "twitter",
      description:
        "Let us send you a private message on Twitter about your outages.",
      icon: "integration-twitter.svg",
      supported: false,
    },
  ]

  return (
    <section id="integrations" className="u-features integrations-list">
      <div className="container">
        <h2>
          We support alerts on{" "}
          {integrations.filter(x => x.supported === true).length} different
          platforms
        </h2>
        <div className="available-integrations">
          {integrations.map((item, index) => {
            return (
              <div
                key={index}
                className={`intg-item_wrapper ${
                  item.supported ? "" : "coming-soon"
                }`}
              >
                <label className="intg-item">
                  <div className="intg--icon">
                    <img src={`/${item.icon}`} alt={item.handlename} />
                  </div>
                  <div className="intg--name">
                    {item.name}
                    {!item.supported && (
                      <span className="coming-soon-tool">Coming soon</span>
                    )}
                  </div>
                  <div className="intg--desc">{item.description}</div>
                </label>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    ImpactAlertOnBusinessImage: file(
      relativePath: { eq: "integrations__section_impact-alert-on-business.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    ImpactAllIntegrationsBrand: file(
      relativePath: { eq: "integrations__all-in-one.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`
export default Integrations
